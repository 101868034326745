import React from 'react';
import { BsInstagram } from 'react-icons/bs';
import { FaFacebookF , FaLinkedinIn   } from 'react-icons/fa';

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a href="https://www.linkedin.com/in/marwan-alrifai-621a14241/" target="_blank">
        <FaLinkedinIn />
      </a>
    </div>
    <div>
      <a href="https://www.facebook.com/profile.php?id=100054142899260" target='_blank'>
        <FaFacebookF />
      </a>
    </div>
    <div>
      <a href="https://www.instagram.com/marwan.alrfa3i/" target="_blank">
        <BsInstagram />
      </a>
    </div>
  </div>
);

export default SocialMedia;