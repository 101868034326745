import React from 'react';
// import About from './container/About/about';
// import Footer from './container/Footer/footer';
// import Header from './container/Header/header';
// import Skills from './container/Skills/skills';
// import Testimonials from './container/Testimonials/testimonials';
// import Work from './container/Work/work';

import {About , Footer , Header , Skills , Work} from './container';
import {Navbar} from './components';
import './App.scss'


const App = () => {
  return (
    <div className="app">
      <Navbar/>
      <Header/>
      <About/>
      <Work/>
      <Skills/>
      {/* <Testimonials/> */}
      <Footer/>
    </div>
  )
}

export default App;