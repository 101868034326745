import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = sanityClient({
  projectId: '6748vos6',
  dataset: 'production',
  apiVersion: '2022-02-01',
  useCdn: true,
  token:'skKtRaqt2FUI9hOM5WIbCpv4QPBCVYVOJJ6961aoiwGpz0nu2xeOP7IuA9dpzRmKt6lF0o39fxEiKKhMUbzuOOrw3J2HecQq2mDiHPt12CyboOzCdAZ6B9Dvr0LkwsUPI2Ndausrtr6F6JLOiE1vt89EwduJ8BlcVvnCPPwfoik1uDYLZ61J',
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);